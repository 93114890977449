<template>
  <path id="path3180" d="M1805.228-1232.619v-22.231l6.125,0Zm-2.441-23.461s0,.007,0,.01,0,.007,0,.01v31.616h-50.435V-1258h3.39a3.8,3.8,0,0,0,2.918-1.531l1.63-2.365a1.453,1.453,0,0,1,.91-.477h12.711a1.446,1.446,0,0,1,.91.477l1.632,2.365a3.8,3.8,0,0,0,2.917,1.531h6.012a.076.076,0,0,1,.022,0l.02,0h17.367Zm-18.929-23.521a.3.3,0,0,1,.216-.089.3.3,0,0,1,.216.089l17.474,17.474a.3.3,0,0,1,.089.215.3.3,0,0,1-.09.216l-1.254,1.252H1794.8a1.214,1.214,0,0,0-.23-1.4L1784-1272.41a1.219,1.219,0,0,0-1.726,0,1.219,1.219,0,0,0,0,1.726l10.242,10.24H1785.9l-6.934-6.932a1.218,1.218,0,0,0-1.726,0,1.22,1.22,0,0,0,0,1.724l5.21,5.208h-3.087a1.434,1.434,0,0,1-.908-.477l-1.632-2.363a3.793,3.793,0,0,0-2.918-1.531h-4.835Zm29.638,23.141a2.184,2.184,0,0,0-1.762-.83h-6.506V-1258a2.448,2.448,0,0,0-1.5-2.25,2.719,2.719,0,0,0,.56-1.659,2.718,2.718,0,0,0-.8-1.94l-17.473-17.474a2.725,2.725,0,0,0-1.942-.8,2.725,2.725,0,0,0-1.942.8l-16.51,16.512H1761.2a3.8,3.8,0,0,0-2.918,1.53l-1.632,2.365a1.426,1.426,0,0,1-.908.477h-3.39a2.444,2.444,0,0,0-2.441,2.441v33.558a2.444,2.444,0,0,0,2.441,2.441h50.44a2.438,2.438,0,0,0,1.847-.848,1.226,1.226,0,0,0,.273-.242,2.554,2.554,0,0,0,.48-.92l8.438-30.62a2.086,2.086,0,0,0-.329-1.828" transform="translate(-1749.405 1282.631)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
</template>

<script>
export default {
name: "IconDossier"
}
</script>

<style scoped>

</style>